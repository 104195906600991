import React from 'react';
import TangramCanvas, { draws } from '../tangramCanvas/TangramCanvas';

const drawsArray = Object.values(draws);

export default class TangramLoader extends React.PureComponent {
  static propTypes = {};

  static defaultProps = {};

  interval = null;

  state = {
    drawIndex: 0,
  };

  /** Will mount. */
  componentWillMount() {
    this.interval = setInterval(() => {
      this.setState({ drawIndex: this.nextIndex() });
    }, 1500);
  }

  /** Will unmount. */
  componentWillUnmount() {
    clearInterval(this.interval);
  }

  nextIndex = () => {
    const { drawIndex } = this.state;
    const next = drawIndex + 1;
    return next >= drawsArray.length ? 0 : next;
  };

  /** @returns {object} JSX. */
  render() {
    const { drawIndex } = this.state;
    const draw = drawsArray[drawIndex];
    return (
      <TangramCanvas draw={draw} />
    );
  }
}
