import React from 'react';
import { FormattedMessage } from 'react-intl';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './marketing-description.messages';
import classNames from './marketing-description.module.scss';

const MarketingDescription = () => (
  <div className={classNames.container}>
    <div className={classNames.preMessage}>
      <FormattedMessage {...messages.PRE_MARKETING} />
    </div>
    <div className={classNames.ideas}>
      <div className={classNames.container}>
        <div className={classNames.logo}>
          <Asset name={ICON_MAP.share_full} />
        </div>
        <div className={classNames.title}>
          <FormattedMessage {...messages.SHARE} />
        </div>
        <div className={classNames.text}>
          <FormattedMessage {...messages.SHARE_TEXT} />
        </div>
      </div>
      <div className={classNames.container}>
        <div className={classNames.logo}>
          <Asset name={ICON_MAP.comment} />
        </div>
        <div className={classNames.title}>
          <FormattedMessage {...messages.CONTRIBUTE} />
        </div>
        <div className={classNames.text}>
          <FormattedMessage {...messages.CONTIBUTE_TEXT} />
        </div>
      </div>
      <div className={classNames.container}>
        <div className={classNames.logo}>
          <Asset name={ICON_MAP.idea} />
        </div>
        <div className={classNames.title}>
          <FormattedMessage {...messages.INSPIRE} />
        </div>
        <div className={classNames.text}>
          <FormattedMessage {...messages.INSPIRE_TEXT} />
        </div>
      </div>
    </div>
  </div>
);

export default MarketingDescription;
