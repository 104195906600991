import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import classNames from './tangram-canvas.module.scss';

export const draws = {
  BASIC: 'tangram--basic',
  CAT: 'tangram--cat',
  SWAN: 'tangram--swan',
  RABBIT: 'tangram--rabbit',
  BAT: 'tangram--bat',
  BEAR: 'tangram--bear',
  FISH: 'tangram--fish',
  SNAKE: 'tangram--snake',
  TURTLE: 'tangram--turtle',
  BUTTERFLY: 'tangram--butterfly',
  CAMEL: 'tangram--camel',
  SQUIRREL: 'tangram--squirrel',
};

export default class TangramCanvas extends React.PureComponent {
  static propTypes = {
    draw: PropTypes.string.isRequired,
  };

  static defaultProps = {};

  /** @returns {object} JSX. */
  render() {
    const { draw } = this.props;
    return (
      <div className={cn(classNames.tangram, classNames[draw])}>
        <div className={cn(classNames.triangle, classNames['triangle--1'])} />
        <div className={cn(classNames.triangle, classNames['triangle--2'])} />
        <div className={cn(classNames.square, classNames['square--1'])} />
        <div className={cn(classNames.triangle, classNames['triangle--3'])} />
        <div className={cn(classNames.triangle, classNames['triangle--4'])} />
        <div className={cn(classNames.triangle, classNames['triangle--5'])} />
        <div className={cn(classNames.parallelogram, classNames['parallelogram--1'])} />
      </div>
    );
  }
}
