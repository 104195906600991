import React, { memo } from 'react';
import { Helmet } from 'react-helmet';

import AboutPage from 'react/pages/about/AboutPage';
import ApplicationLayout from 'react/business/layout/ApplicationLayout';
import FormattedMessageChildren from 'react/generic/i18n/FormattedMessageChildren';

import messages from './about.messages';

const AboutView = () => (
  <ApplicationLayout>
    <FormattedMessageChildren {...messages.TITLE}>
      {message => (
        <Helmet>
          <title>{message}</title>
        </Helmet>
      )}
    </FormattedMessageChildren>
    <AboutPage />
  </ApplicationLayout>
);

export default memo(AboutView);
