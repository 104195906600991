import React from 'react';

import MarketingDescription from 'react/business/marketing/description/MarketingDescription';
import MarketingQuote from 'react/business/marketing/quote/MarketingQuote';
import MarketingAnimation from 'react/business/marketing/animation/MarketingAnimation';
import MarketingFooter from 'react/business/marketing/footer/MarketingFooter';

import classNames from './about-page.module.scss';

const AboutPage = () => (
  <div className={classNames.container}>
    <MarketingDescription />
    <MarketingQuote />
    <MarketingAnimation />
    <MarketingFooter />
  </div>
);

AboutPage.displayName = 'AboutPage';

export default AboutPage;
