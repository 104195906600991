import React from 'react';
import { FormattedMessage } from 'react-intl';

import messages from './marketing-footer.messages';
import classNames from './marketing-footer.module.scss';

const MarketingFooter = () => (
  <div className={classNames.container}>
    <div className={classNames.text}>
      <FormattedMessage {...messages.TANGRAM_FOOT} />
    </div>
  </div>
);

export default MarketingFooter;
