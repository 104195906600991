import React from 'react';
import { FormattedMessage } from 'react-intl';

import Asset, { ICON_MAP } from 'react/generic/asset/Asset';

import messages from './marketing-quote.messages';
import classNames from './marketing-quote.module.scss';

const MarketingQuote = () => (
  <div className={classNames.container}>
    <div className={classNames.open}>
      <Asset name={ICON_MAP.quote_open} />
    </div>
    <div className={classNames.text}>
      <FormattedMessage {...messages.QUOTE} />
    </div>
    <div className={classNames.close}>
      <Asset name={ICON_MAP.quote_close} />
    </div>
  </div>
);

export default MarketingQuote;
