import React from 'react';
import { FormattedMessage } from 'react-intl';

import TangramLoader from 'react/business/tangramLoader/TangramLoader';
import messages from './marketing-animation.messages';

import classNames from './marketing-animation.module.scss';

const MarketingAnimation = () => (
  <div className={classNames.tangramContainer}>
    <div className={classNames.animationContainer}>
      <TangramLoader />
    </div>
    <div className={classNames.text}>
      <p className={classNames.sub}>
        <FormattedMessage {...messages.TANGRAM_SUB} />
      </p>
      <h1 className={classNames.title}>
        <FormattedMessage {...messages.TANGRAM_TITLE} />
      </h1>
      <p className={classNames.description}>
        <FormattedMessage {...messages.TANGRAM_DESC} />
      </p>
    </div>
  </div>
);

export default MarketingAnimation;
